import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import BrandCTA from '../../../components/brand-cta'
import { withPrefix } from "gatsby"
import ExternalLink from '../../../components/external-link'
import ActiveLink from '../../../components/active-link'
import Hero from '../../../components/hero'
import codingTable from '../../../images/codingtable.png'

const metaTags = {
  description: 'Learn about suggested billing codes for SUPPRELIN® LA (histrelin acetate) subcutaneous implant.',
  keywords: 'Homepage',
  title: 'Suggested Billing & Coding | SUPPRELIN® LA (histrelin acetate)'
}

const externalLinksMetaTags = {
  category: 'External Link',
  action: 'Click',
  label: 'Deerfield Homepage',
}

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>Suggested billing and coding </h1>
      </Col>
    </Row>
    <Row center='xs'>
      <Col xs={12}>
        <img src={codingTable} alt="Suggested Billing Coding Table" title="Suggested Billing Coding Table" />
        <p><strong>Coding is part of the clinical decision. Please use codes that most accurately reflect the procedures performed. Suggestions by <span className='block_nowrap'>Endo Pharmaceuticals Inc.</span> do not guarantee reimbursement or take the place of professional coding advice.</strong></p>
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          href={'/hcp/downloads-office-support'}
          LinkCaption="Discover important reimbursement forms for your practice - billing coding"
        >
          Discover important reimbursement forms for your practice

        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          ExternalLink
          href={withPrefix('/pdfs/SP-05689_Supprelin_LA_Caregiver_Brochure_Digital.pdf')}
          LinkCaption="Download the SUPPRELIN<sup>&reg;</sup> LA caregiver brochure: important information for patients - billing coding"
        >
         Download the SUPPRELIN<sup>&reg;</sup> LA caregiver brochure: important information for patients
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
